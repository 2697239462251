import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import SEO from "../../components/seo";
import Hero from "../../components/hero";
import backdrop from "../../images/header-image-04.png";
import toolImage from "../../images/interactive-tool-image_vafseo.jpg";
import ModalGame from "../../components/modalGame";
import ModalSingleVideo from "../../components/modalSingleVideo";
import ResourceCard from "../../components/resources/resourceCard";
import References from "../../components/references";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Pipeline = ({ location }) => {
	const [openGameModal, setOpenGameModal] = useState(false);
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [videoDoc, setVideoDoc] = useState();
	const [videoFile, setVideoFile] = useState("");
	const [gameDoc, setGameDoc] = useState();
	let scrollRef = useRef();
	let curPath = undefined;

	let runAfterRender = () => {
		const myElem = document.getElementById("myElem")
		if (myElem) {
			//do important stuff
		}
	}

	/* Auto-scroll to Hash Link (from a different Page) */
	useEffect(
		() => {
			var hash = location.hash;
			//console.log("Hash: " + hash);
			if (hash !== "") {
				var _this = this;
				window.requestAnimationFrame(function () {
					setTimeout(function () {
						var hashJump = hash.replace("#", "").replace("%20", " ");
						var top = 0;
						var bodyTop = document.querySelector('body').getBoundingClientRect().top;
						if (hashJump === 'clinical-programs') {
							top = scrollRef.current.getBoundingClientRect().top;
						}
						setTimeout(function () {
							var y = top - bodyTop - 210;
							//console.log("Y: " + y);
							window.scrollTo({ behavior: 'smooth', top: y });
						}, 200);
						//
						/* Intercept Footer Hash Links, Prevent Default, and Scroll to Hash */
						curPath = location.pathname;
						//console.log("curPath: " + curPath);
						var hashLink = document.querySelectorAll('.footer-hash');
						for (var i = 0; i < hashLink.length; ++i) {
							var ref = hashLink[i];
							ref.addEventListener('click', function (e) {
								var link = this.getAttribute('href');
								if (link.indexOf('#') > 0) {
									var parts = link.split('#');
									if (parts[0] === curPath) {
										e.preventDefault();
										var hashVal = parts[1];
										var hashRef = document.getElementById(hashVal);
										var bodyTop = document.querySelector('body').getBoundingClientRect().top;
										//console.log("Hash #: " + hashVal);
										setTimeout(function () {
											var top = hashRef.getBoundingClientRect().top;
											var y = top - bodyTop - 170;
											//console.log("Y hashLink: " + y);
											window.scrollTo({ behavior: 'smooth', top: y });
										}, 200);
										//console.log("Hash Link is on SAME PAGE");
									}
								}
							});
						}
					}, 10)
				});
			}
		}, [location]
	);

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await axios.get(
					"https://d2zzqzqmnbfbm9.cloudfront.net/documents?category=Disease%20Education"
				);
				if (!result.data.errors) {
					const videoData = result.data.data.filter(d => d.id === 5690)[0];
					setVideoDoc(videoData);
					setVideoFile(
						`https://akebia-file-sync.s3.amazonaws.com/V2 HIF Pathway Live Action Video 10.14.20.mp4`
					);

					const gameData = result.data.data.filter(d => d.id === 6518)[0];
					setGameDoc(gameData);
				}
			} catch (e) {
				// console.log(e);
			}
		}

		fetchData();
	}, []);

	return (
		<Layout contact>
			<SEO title="Pipeline" />
			<Hero background={backdrop}>
				<div className="col-sm-6">
					<h1 className="hero-heading">Pipeline</h1>
					<p className="hero-copy">
						In order to improve the lives of those living with CKD, we know that
						we must think boldly and move bold thinking into action. That is why
						we work with partners across the globe to pioneer and grow new areas
						of research and development.
					</p>
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad">
				<div className="container breadcrumbs">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/kidney-disease"
									className=""
								>
									Science
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/pipeline"
									className="active"
								>
									Pipeline
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div className="section">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h2 className="section-heading">What Is the HIF Pathway?</h2>
						</div>
						<div className="col-sm-6">
							<p>
								The hypoxia-inducible factor (HIF) pathway is the master
								regulator of our body’s adaptive response to oxygen
								availability. This fundamental oxygen-sensing pathway is
								responsible for the transcription of a broad range of genes
								involved in maintaining our biological homeostasis.<sup>1</sup>{" "}
								Scientific advancement in understanding how the HIF pathway
								regulates physiological processes culminated in 2019 with the
								award of the Nobel Prize in Physiology or Medicine. The
								discovery of HIF has laid the foundation to understanding the
								central role of oxygen sensing in many diseases, including
								anemia due to CKD.<sup>2,3</sup>
							</p>
						</div>
						<div className="col-sm-6">
							<p>
								<AnchorLink href="#resources">
									Watch a live-action video
								</AnchorLink>{" "}
								to understand how the HIF pathway orchestrates an adaptive,
								physiological response to anemia. And then, get in on the action
								and experience the science firsthand with our{" "}
								<AnchorLink href="#resources">interactive game</AnchorLink>.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="row gray-bg">
				<div className="col">
					<div className="container">
						<div className="section">
							<div className="row">
								<div className="col-sm-6">
									<h3 ref={scrollRef} id="clinical-programs" className="section-heading">
										Vadadustat<br />Global Phase 3 Program
									</h3>
									<p>
										Explore the randomized, active-controlled, non-inferiority studies which evaluated the efficacy and safety of vadadustat, a hypoxia-inducible factor prolyl hydroxylase inhibitor (HIF-PHI).
									</p>
									<a
										target="_blank"
										href="/clinical-tool"
										className="btn btn-primary"
									>
										View
									</a>
								</div>
								<div className="col-sm-6">
									<img
										src={toolImage}
										alt="Vadadustat Clinical Program"
										style={{ width: "100%", height: "auto", 'maxWidth': "450px", 'marginTop': "20px" }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="resources" className="container section">
				<div className="row">
					<div className="col">
						<h4 className="section-heading">Resources</h4>
					</div>
				</div>
				<div className="row">
					{videoDoc && (
						<ResourceCard
							category="Video"
							callback={() => {
								setOpenVideoModal(true);
							}}
							resource={videoDoc}
						/>
					)}
					{gameDoc && (
						<ResourceCard
							category="Game"
							callback={() => {
								setOpenGameModal(true);
							}}
							resource={gameDoc}
						/>
					)}
				</div>
			</div>
			<References>
				<p>
					<strong>1.</strong> Ke Q, et al. <i>Mol Pharmacol.</i>{" "}
					2006;70(5):1469-1480.
					<br />
					<strong>2.</strong> Liao C, et al. <i>Am J Pathol.</i>{" "}
					2020;190(8):1584-1595.
					<br />
					<strong>3.</strong> Liu J, et al. <i>Int J Mol Sci.</i>{" "}
					2017;18(5):950.
				</p>
			</References>
			{gameDoc && (
				<ModalGame
					isOpen={openGameModal}
					onRequestClose={() => {
						setOpenGameModal(false);
					}}
				/>
			)}
			{videoDoc && (
				<ModalSingleVideo
					isOpen={openVideoModal}
					onRequestClose={() => {
						setOpenVideoModal(false);
					}}
					playlist={[
						{
							video: videoFile,
							title: videoDoc.title__v,
							description: videoDoc.medical_description__c,
							isWide: true,
						},
					]}
				/>
			)}
		</Layout>
	);
};

export default Pipeline;
